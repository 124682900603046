import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  agregar(data) {
    var params = data
    var url = ConfigAPI.baseURL + "dashboard" + Session.getToken();
    return instance.post(url,params);
  },
  editar(data) {
    var params = data
    var url = ConfigAPI.baseURL + "dashboard/" + data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminar(id) {
    var url = ConfigAPI.baseURL + "dashboard/"+ id + Session.getToken();
    return instance.delete(url);
  },
  getSessionUser() {
    var url = ConfigAPI.baseURL + "getInfoSession" + Session.getToken();

    return instance.get(url);
  },
  actualizarContenidoDashboard(data) {
    var params = data

    var url = ConfigAPI.baseURL + "dashboard/" + data.id + '/widget' + Session.getToken();
    return instance.post(url,params);
  },
}

export default servicesAPI;
